import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { InputFieldComponent } from '@shared/form-inputs/input-field/input-field.component';
import { User } from '@shared/interfaces';
import { InputSwitchModule } from 'primeng/inputswitch';
@Component({
  selector: 'app-user-profile-form',
  standalone: true,
  imports: [
    InputFieldComponent,
    PageTitleComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputSwitchModule,
  ],
  templateUrl: './user-profile-form.component.html',
  styleUrl: './user-profile-form.component.scss',
})
export class UserProfileFormComponent implements OnInit {
  profileForm: FormGroup;

  @Input() isMyProfile = true;

  @Output() onFormPopulateCompleteHandler: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set user(data: User | null) {
    this.userDetails = data;
    this.populateProfileForm();
  }

  public userDetails: User | null = null;

  constructor(private readonly parentControl: ControlContainer) {}

  ngOnInit(): void {
    this.profileForm = this.parentControl.control as FormGroup;
    this.populateProfileForm();
  }

  populateProfileForm(): void {
    if (!this.profileForm || !this.userDetails) return;

    this.profileForm.patchValue(
      {
        name: this.userDetails.first_name + ' ' + this.userDetails.last_name,
        email: this.userDetails.email,
        role: this.userDetails.groups[0].name,
        notificationsEnabled: this.userDetails.notifications_enabled,
      },
      { emitEvent: false },
    );
    this.onFormPopulateCompleteHandler.emit();
  }
}
