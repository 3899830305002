import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '@shared/interfaces/confirmation-dialog.interface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  ref: DynamicDialogRef | undefined;

  constructor(private dialogService: DialogService) {}

  openDialog(data: ConfirmationDialogData): void {
    this.ref = this.dialogService.open(ConfirmationDialogComponent, {
      showHeader: false,
      closable: false,
      width: '50%',
      style: { 'max-width': '500px', 'min-width': '300px' },
      data,
    });

    this.ref.onClose.subscribe((confirmation: boolean) => {
      if (confirmation) {
        data.confirmCallback();
      }

      if (!confirmation && data.cancelCallback) {
        data.cancelCallback();
      }
    });
  }
}
