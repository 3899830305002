<div class="tabs">
  <ul class="nav nav-tabs d-flex">
    @for (tab of navigationTabs; track tab; let i = $index) {
      @if (!tab.permission || (tab.permission && checkVisibilityByPermission(tab.permission))) {
        <li class="nav-item" (click)="selectTab(tab.key)" (keyup.enter)="keyup()" [tabindex]="i">
          <a
            [routerLink]="tab.routerLink"
            class="nav-link"
            [class.active]="selectedTab === tab.key"
          >
            <div class="d-flex justif-content-center g-8">
              <div><span [class]="tab.icon"></span></div>
              <div>{{ tab.title }}</div>
            </div></a
          >
        </li>
      }
    }
  </ul>
</div>
