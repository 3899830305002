import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { GroupNotification } from '@shared/interfaces/notification.interface';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { NotificationCardComponent } from '../notification-card/notification-card.component';

@Component({
  selector: 'app-notification-table',
  standalone: true,
  imports: [
    DataViewModule,
    ButtonModule,
    CommonModule,
    PageTitleComponent,
    NotificationCardComponent,
  ],
  templateUrl: './notification-table.component.html',
  styleUrl: './notification-table.component.scss',
})
export class NotificationTableComponent {
  @Input() notificationGroupedData: GroupNotification[] = [];
}
