import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { User } from '@shared/interfaces';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import {
  ImageCropperComponent,
  ImageCroppedEvent,
  ImageTransform,
  OutputFormat,
} from 'ngx-image-cropper';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { FormsModule } from '@angular/forms';
import { SliderChangeEvent, SliderModule } from 'primeng/slider';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { get } from 'lodash';

export const MODE = {
  view: 'view',
  upload: 'upload',
};

@Component({
  selector: 'app-upload-profile-picture-dialog',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    AvatarComponent,
    CommonModule,
    ImageCropperComponent,
    FileUploadModule,
    FormsModule,
    SliderModule,
  ],
  templateUrl: './upload-profile-picture-dialog.component.html',
  styleUrl: './upload-profile-picture-dialog.component.scss',
})
export class UploadProfilePictureDialogComponent {
  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  @Input() profilePictureDialogVisible = false;

  @Input() user: User | null = null;

  @Output() toggleDialogHandler: EventEmitter<void> = new EventEmitter<void>();

  @Output() uploadProfilePictureHandler: EventEmitter<Blob | null> =
    new EventEmitter<Blob | null>();

  constructor(private readonly toastMessageService: ToastMessageService) {}

  mode = MODE.view;

  displayModes = MODE;

  newImageEvent: Event | null = null;

  zoomLevel = 10;

  transform: ImageTransform = {
    translateUnit: 'px',
  };

  imageBlob: Blob | null = null;

  hasExistingPictureRemoved = false;

  submitDisabled = false;

  isProcessingImage = false;

  acceptedExtensions = '.png, .jpeg, .jpg, .jfif, .img';

  imageOutputFormat: OutputFormat;

  closeDialog(): void {
    this.resetFileInput();
    this.toggleDialogHandler.emit();
    this.mode = MODE.view;
    this.hasExistingPictureRemoved = false;
    this.imageBlob = null;
    this.isProcessingImage = false;
    this.zoomLevel = 10;
    this.imageOutputFormat = this.getOutputImageType('');
  }

  toggleMode(): void {
    this.mode = this.mode === MODE.view ? MODE.upload : MODE.view;
  }

  get fullName() {
    return [this.user?.first_name ?? '', this.user?.last_name ?? ''].join(' ');
  }

  fileChangeEvent(event: Event): void {
    this.isProcessingImage = true;
    this.imageOutputFormat = this.getOutputImageType(
      get(event, 'target.value', '').split('.').pop(),
    );
    this.newImageEvent = event;
    this.toggleMode();
  }

  getOutputImageType(type: string | null | undefined): OutputFormat {
    switch (type) {
      case 'jpg':
      case 'jpeg':
      case 'jfif':
        return 'jpeg' as OutputFormat;
      case 'webp':
        return 'webp' as OutputFormat;
      case 'bmp':
        return 'bmp' as OutputFormat;
      case 'png':
        return 'png' as OutputFormat;
      default:
        return 'jpeg' as OutputFormat;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.isProcessingImage = false;
    this.imageBlob = event.blob ?? null;
  }

  loadImageFailed() {
    this.resetFileInput();
    this.imageBlob = null;
    this.mode = MODE.view;
    this.isProcessingImage = false;
    this.toastMessageService.showErrorToast(
      'Please upload a JPEG, PNG, or GIF file.',
      'Invalid File Type',
    );
  }

  onSelectProfilePicture(event: FileSelectEvent): void {
    this.newImageEvent = event.originalEvent;
    this.toggleMode();
  }

  zoomOut() {
    this.zoomLevel -= 1;
    this.transform = {
      ...this.transform,
      scale: this.zoomLevel / 10,
    };
  }

  zoomIn() {
    this.zoomLevel += 1;
    this.transform = {
      ...this.transform,
      scale: this.zoomLevel / 10,
    };
  }

  onZoomLevelChange(event: SliderChangeEvent) {
    this.zoomLevel = event.value ?? 10;
    this.transform = {
      ...this.transform,
      scale: this.zoomLevel / 10,
    };
  }

  resetFileInput() {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
    this.newImageEvent = null;
  }

  removeImage() {
    this.imageBlob = null;
    this.resetFileInput();
    this.toggleMode();
  }

  saveUserProfile() {
    this.submitDisabled = true;
    this.uploadProfilePictureHandler.emit(
      this.hasExistingPictureRemoved && !this.imageBlob ? null : this.imageBlob,
    );
  }

  removeExistingProfilePicture() {
    this.hasExistingPictureRemoved = true;
  }

  imageLoaded() {
    console.log('Image loaded');
  }

  cropperReady() {
    this.isProcessingImage = true;
  }
}
