<div class="notification-item d-flex align-items-start pb-10 pt-10">
  @switch (notification.notification_type) {
    @case (AppNotificationType.CLAIM_DUE_REMINDER) {
      <ng-container *ngTemplateOutlet="notificationDue"></ng-container>
    }
    @case (AppNotificationType.TASK_DUE_REMINDER) {
      <ng-container *ngTemplateOutlet="notificationDue"></ng-container>
    }
    @case (AppNotificationType.TASK_DELAYED_REMINDER) {
      <ng-container *ngTemplateOutlet="delayedNotification"></ng-container>
    }
    @case (AppNotificationType.CLAIM_DELAY_REMINDER) {
      <ng-container *ngTemplateOutlet="delayedNotification"></ng-container>
    }
    @default {
      @if (notification.created_by) {
        <div class="pr-15 pl-5 d-flex">
          <app-avatar
            [image]="notification.created_by.profile_picture?.document || null"
            [name]="notification.created_by.first_name + ' ' + notification.created_by.last_name"
            [height]="40"
            [width]="40"
          ></app-avatar>
        </div>
      }
    }
  }

  <div class="pl-10">
    <div class="d-flex g-10 align-items-center">
      <h4 class="title mb-0 mt-0 fs-12">{{ notification.title }}</h4>
      <div class="time-ago">{{ notification.created_date | timeAgo }}</div>
    </div>
    @if (notification.url_text) {
      <div class="url">
        <a [routerLink]="notification.path">{{ notification.url_text }}</a>
      </div>
    }

    @if (notification.extra_attribute && notification.extra_attribute.message) {
      <div class="extra-attribute d-flex full-width" [ngStyle]="notification.extra_attribute.style">
        @if (notification.extra_attribute.icon) {
          <div class="icon-container">
            <i
              [class]="notification.extra_attribute.icon"
              [ngStyle]="{
                color: notification.extra_attribute.style['color'],
                'font-size': '12px',
                'padding-top': '3px',
              }"
            ></i>
          </div>
        }
        <p [ngStyle]="{ color: notification.extra_attribute.style['color'] }">
          {{ notification.extra_attribute.message }}
        </p>
      </div>
    }
  </div>

  <ng-template #notificationDue>
    <div class="remainder-bell mr-12">
      <i class="pi pi-bell mr-4 p-text-secondary" style="font-size: 24px"></i>
    </div>
  </ng-template>

  <ng-template #delayedNotification>
    <div class="delayed-bell mr-12 position-relative">
      <img src="assets/icons/notification-delayed.svg" alt="delayed" />
    </div>
  </ng-template>
</div>
