import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { User } from '@shared/interfaces';
import { get } from 'lodash';
import { Button } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'app-profile-dropdown',
  standalone: true,
  imports: [AvatarComponent, CommonModule, OverlayPanelModule, Button, RouterModule],
  templateUrl: './profile-dropdown.component.html',
  styleUrl: './profile-dropdown.component.scss',
})
export class ProfileDropdownComponent {
  @Input() user: User | null = null;

  get fullName() {
    return [this.user?.first_name ?? '', this.user?.last_name ?? ''].join(' ');
  }

  get groupName() {
    return get(this.user, 'groups[0].name') ?? '---';
  }
}
