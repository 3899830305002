import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service for managing the loader state.
 */
@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private apiCount = 0;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  /**
   * Shows the loader.
   * If the API count is 0, sets the isLoadingSubject to true.
   */
  showLoader() {
    if (this.apiCount === 0) {
      this.isLoadingSubject.next(true);
    }
    this.apiCount++;
  }

  /**
   * Hides the loader.
   * Decreases the API count by 1.
   * If the API count becomes 0, sets the isLoadingSubject to false.
   */
  hideLoader() {
    this.apiCount--;
    if (this.apiCount === 0) {
      this.isLoadingSubject.next(false);
    }
  }
}
