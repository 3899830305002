import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProjectFormComponent } from './project-form/project-form.component';

import { ActivatedRoute, Router } from '@angular/router';
import { BackButtonComponent } from '../../../../shared/components/back-button/back-button.component';
import { PageTitleComponent } from '../../../../shared/components/page-title/page-title.component';
import { get } from 'lodash';
import { ProjectService } from '../../services/project/project.service';
import { CreateProjectPayload, Region } from '@shared/interfaces';
import { ToastNotificationSeverity } from '@shared/enums';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionService } from '@Services/permission/permission.service';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { FormStatus } from '@shared/enums/form/form';

@Component({
  selector: 'app-create-project',
  standalone: true,
  imports: [ProjectFormComponent, BackButtonComponent, PageTitleComponent],
  templateUrl: './create-project.component.html',
  styleUrl: './create-project.component.scss',
})
export class CreateProjectComponent implements OnInit {
  @ViewChild(ProjectFormComponent) projectFormComponent!: ProjectFormComponent;

  @Input() regions: Region[] = [];

  constructor(
    private readonly router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly projectService: ProjectService,
    private readonly toastMessageService: ToastMessageService,
    private readonly permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.regions = get(this._activatedRoute, 'data._value.regions') ?? [];
  }

  /**
   *
   * @param data
   */
  createNewProject(data: CreateProjectPayload) {
    this.projectService.createProject(data).subscribe({
      next: (project) => {
        this.toastMessageService.emitMessage({
          severity: ToastNotificationSeverity.SUCCESS,
          summary: 'Success',
          details: `Project "${get(project, 'name')}" created successfully.`,
        });
        this.router.navigate(['/project']);
        this.projectFormComponent.reset();
      },
      error: (httpError: HttpErrorResponse) => {
        this.toastMessageService.showValidationErrors(
          httpError.error,
          httpError?.error?.error_message ?? 'Unable to create new project.',
        );
        this.projectFormComponent.formStatus = FormStatus.InProgress;
      },
    });
  }

  cancel() {
    this.router.navigate(['/project']);
  }

  get hasCreateProjectPermission(): boolean {
    return this.permissionService.hasPermission(AllGroupPermissions.ADD_PROJECT);
  }
}
