import { ConfirmationDialogService } from '@Services/dialogs/confirmation-dialog/confirmation-dialog.service';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { UserService } from '@Services/user/user.service';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UsersTableConfig } from '@constants/usersTableConfig.const';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { TableComponent } from '@shared/components/table/table.component';
import { ToastNotificationSeverity } from '@shared/enums';
import { SelectedUserGroup, User, UserRole } from '@shared/interfaces';
import { TableActions, TableSignals } from '@shared/interfaces/table';
import { ButtonModule } from 'primeng/button';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-users-list',
  standalone: true,
  imports: [PageTitleComponent, ButtonModule, TableComponent, CommonModule],
  templateUrl: './users-list.component.html',
})
export class UsersListComponent implements OnInit, OnDestroy {
  @ViewChild(TableComponent) userListTableComponent!: TableComponent<User>;

  usersTableConfig = { ...UsersTableConfig };

  userRoles: UserRole[];

  subscription: Subscription;

  constructor(
    private readonly userService: UserService,
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly router: Router,
    private readonly toastMessageService: ToastMessageService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit() {
    this.getUserRoles();
  }

  getUserRoles() {
    this.subscription.add(
      this.userService.getUserRoles().subscribe((roles) => {
        this.userRoles = roles;
        this.usersTableConfig.formatData = this.formatUsersData.bind(this);
      }),
    );
  }

  formatUsersData(data: User[]) {
    return data.map(
      (item) =>
        ({
          ...item,
          username: item.first_name + ' ' + item.last_name,
          user_role: {
            id: this.userRoles.find((role) => role.name === item.groups[0]?.name)?.id ?? 'N/A',
            name: this.userRoles.find((role) => role.name === item.groups[0]?.name)?.name ?? 'N/A',
            description:
              this.userRoles.find((role) => role.name === item.groups[0]?.name)?.description ??
              'N/A',
          },
        }) as unknown as User,
    );
  }

  handleTableSignals(event: TableSignals<User> | TableSignals<SelectedUserGroup>) {
    const data = event.data;
    if (event.action === TableActions.viewProjectDetails && 'userpreference' in data) {
      const userPreference = data.userpreference as { id: string };
      this.router.navigate([`/user/profile/`], { queryParams: { userId: userPreference.id } });
    } else if (event.action === TableActions.changeRole && 'name' in event.data) {
      this.openUserChangeRoleDialog(event.data);
    }
  }

  openUserChangeRoleDialog(user: SelectedUserGroup) {
    this.confirmationDialogService.openDialog({
      title: 'Change Role Confirmation',
      description: `You are about to change the role of ${user.username} to ${user.name}. Please note that this action may limit or remove access to certain features, sections, or content.`,
      subDescription: 'Are you sure you want to proceed?',
      confirmCallback: this.confirmCallback.bind(this, user),
      cancelCallback: this.cancelCallback.bind(this),
    });
  }

  cancelCallback() {
    this.userListTableComponent.resetData();
  }

  confirmCallback(user: SelectedUserGroup) {
    if (!('userId' in user && 'name' in user)) {
      return;
    }

    this.subscription.add(
      this.userService.updateUserRole(user.userId, user.name).subscribe({
        next: (res) => {
          this.toastMessageService.emitMessage({
            severity: ToastNotificationSeverity.SUCCESS,
            summary: 'Success',
            details: res.message,
          });
        },
        error: (httpError: HttpErrorResponse) => {
          this.toastMessageService.showValidationErrors(
            httpError.error,
            httpError?.error?.error_message,
          );
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
