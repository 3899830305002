<div class="header">
  <div class="header-container">
    <div class="header-left">
      @if (user) {
        <span class="header-left">Welcome&nbsp;{{ firstName }}</span>
      } @else {
        <span class="header-left">Welcome</span>
      }
    </div>
    <div class="header-right flex align-items-center">
      <a class="notification-bell position-relative" [routerLink]="'/notifications'">
        @if (notificationBadgeCount) {
          <div class="badge-notification">{{ notificationBadgeCount }}</div>
        }
        <p-button styleClass="notification-bell" [text]="true">
          <i class="pi pi-bell mr-4 p-text-secondary" style="font-size: 24px"></i>
        </p-button>
      </a>

      <app-profile-dropdown [user]="user" />
    </div>
  </div>
</div>
