import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { Country, ProjectDetailsResponse } from '@shared/interfaces';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ProjectService } from '../../services/project/project.service';
import { get } from 'lodash';
import { ToastNotificationSeverity } from '@shared/enums';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BackButtonComponent } from '@shared/components/back-button/back-button.component';
import { SelectedProjectDataService } from '../../services/selected-project-data/selected-project-data.service';
import { Subscription } from 'rxjs';
import { NavigationTabsComponent } from './navigation-tabs/navigation-tabs.component';
import { WeatherWidgetComponent } from '@shared/components/weather-widget/weather-widget.component';
import { SecretKeys } from '@shared/interfaces/common.interface';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { LocationWidgetComponent } from '@shared/components/location-widget/location-widget.component';
import { ProjectDetailTabKeys } from '@shared/enums/project/project-details-tabs';

@Component({
  selector: 'app-project-details-page-layout',
  standalone: true,
  imports: [
    PageTitleComponent,
    BackButtonComponent,
    RouterOutlet,
    NavigationTabsComponent,
    WeatherWidgetComponent,
    ButtonModule,
    TooltipModule,
    LocationWidgetComponent,
  ],
  templateUrl: './project-details-page-layout.component.html',
  styleUrl: './project-details-page-layout.component.scss',
})
export class ProjectDetailsPageLayoutComponent implements OnInit, OnDestroy {
  @Input() countries: Country[] = [];

  projectId: string | null = null;

  projectDetails: ProjectDetailsResponse | null = null;

  subscription: Subscription;

  activeTabIndex = 0;

  secretKeys: SecretKeys;

  exportProjectInProgress = false;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly projectService: ProjectService,
    private readonly toastMessageService: ToastMessageService,
    private readonly selectedProjectDataService: SelectedProjectDataService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.projectId = get(this.activatedRoute, 'snapshot.params.id');

    if (!this.projectId) {
      this.toastMessageService.emitMessage({
        severity: ToastNotificationSeverity.ERROR,
        summary: 'Error',
        details: 'ProjectId not provided',
      });
      this.router.navigate(['project']);
      return;
    }

    this.subscription.add(
      this.selectedProjectDataService.getSelectedProject().subscribe((state) => {
        this.projectDetails = state.data;
      }),
    );

    this.getProjectDetails(this.projectId);

    const activeTab = window.location.hash.substring(1);
    this.setActiveTab(activeTab as ProjectDetailTabKeys);

    this.getSecretKeys();
  }

  setActiveTab(hashIdentifier: ProjectDetailTabKeys) {
    switch (hashIdentifier) {
      case ProjectDetailTabKeys.General:
        this.activeTabIndex = 0;
        break;

      case ProjectDetailTabKeys.Task:
        this.activeTabIndex = 1;
        break;

      default:
        this.activeTabIndex = 0;
    }
  }

  getSecretKeys() {
    const subscription = this.projectService.getSecretKeys().subscribe({
      next: (res: SecretKeys) => {
        this.secretKeys = res;
      },
      error: (httpError: HttpErrorResponse) => {
        this.toastMessageService.showValidationErrors(
          httpError.error,
          httpError?.error?.error_message ?? 'Unable to fetch client keys',
        );
      },
    });
    this.subscription.add(subscription);
  }

  getProjectDetails(projectId: string) {
    this.subscription.add(
      this.projectService.getProjectDetails(projectId).subscribe({
        next: (data: ProjectDetailsResponse) => {
          this.projectDetails = data;
          this.selectedProjectDataService.setSelectedProject(data);
        },
        error: (httpError: HttpErrorResponse) => {
          this.toastMessageService.showValidationErrors(
            httpError.error,
            httpError?.error?.error_message,
          );
          this.selectedProjectDataService.setSelectedProject(null);
        },
      }),
    );
  }

  reloadProjectDetails() {
    this.getProjectDetails(this.projectId as string);
  }

  ngOnDestroy(): void {
    this.selectedProjectDataService.reset();
    this.subscription.unsubscribe();
  }

  exportProjectDetailsCSV() {
    this.exportProjectInProgress = true;
    this.projectService
      .downloadProjectDetailsCSV(this.projectId as string)
      .then(() => {
        this.exportProjectInProgress = false;
      })
      .catch((error: HttpErrorResponse) => {
        this.exportProjectInProgress = false;
        this.toastMessageService.showValidationErrors(
          error.error,
          error?.error?.error_message ?? 'Unable to download project details.',
        );
      });
  }

  get siteCode(): string {
    return get(this.projectDetails, 'site.code') ?? '';
  }
}
