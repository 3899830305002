import { ColumnFilterType, TextFilterModeName } from '@shared/enums/filters/table-filters';
import { DataTableConfig, TableActions, TableColumnDisplay } from '../shared/interfaces/table';
import { User } from '@shared/interfaces';

export const UsersTableConfig: DataTableConfig<User> = {
  endPoint: 'users/',
  showCheckboxes: true,
  sortBy: 'name',
  fixedWidth: true,
  fixedMinWidth: '768px',
  columns: [
    {
      title: 'Name',
      name: 'first_name',
      displayType: TableColumnDisplay.user,
      action: TableActions.viewProjectDetails,
      width: '30%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'first_name',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'User Name',
        fieldName: 'first_name',
        type: ColumnFilterType.TEXT,
      },
    },
    {
      title: 'Email',
      name: 'email',
      displayType: TableColumnDisplay.value,
      width: '30%',
      sortable: false,
      hasColumnFilters: false,
      sortConfig: {
        sortKey: 'email',
      },
    },
    {
      title: 'Role',
      name: 'name',
      displayType: TableColumnDisplay.userRole,
      width: '40%',
      hasColumnFilters: true,
      sortable: true,
      sortConfig: {
        sortKey: 'groups__name',
      },
      filterConfig: {
        options: Object.values(TextFilterModeName),
        selectedFilter: TextFilterModeName.CONTAINS,
        placeholder: 'Group Name',
        fieldName: 'groups__name',
        type: ColumnFilterType.TEXT,
      },
    },
  ],
};
