import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { User } from '@shared/interfaces';
import { get } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { UploadProfilePictureDialogComponent } from './upload-profile-picture-dialog/upload-profile-picture-dialog.component';
import { Subscription } from 'rxjs';
import { FileService } from '@Services/file/file.service';
import { AttachmentDocument } from '@shared/interfaces/common.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '@Services/user/user.service';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { DocumentEntityType } from '@shared/enums/document/document';

@Component({
  selector: 'app-main-profile-picture',
  standalone: true,
  imports: [AvatarComponent, ButtonModule, UploadProfilePictureDialogComponent],
  templateUrl: './main-profile-picture.component.html',
  styleUrl: './main-profile-picture.component.scss',
})
export class MainProfilePictureComponent {
  @ViewChild(UploadProfilePictureDialogComponent)
  uploadProfilePictureDialogComponent!: UploadProfilePictureDialogComponent;

  @Input() user: User | null = null;

  @Input() isMyProfile = true;

  @Output() refreshUserDataHandler: EventEmitter<void> = new EventEmitter<void>();

  subscription: Subscription;

  profilePictureDialogVisible = false;

  constructor(
    private readonly fileService: FileService,
    private readonly userService: UserService,
    private readonly toastMessageService: ToastMessageService,
  ) {
    this.subscription = new Subscription();
  }

  get groupName() {
    return get(this.user, 'groups[0].name') ?? '---';
  }

  get fullName() {
    return [this.user?.first_name ?? '', this.user?.last_name ?? ''].join(' ');
  }

  toggleProfilePictureDialog(): void {
    this.profilePictureDialogVisible = !this.profilePictureDialogVisible;
  }

  openProfilePicture(): void {
    this.profilePictureDialogVisible = true;
    this.uploadProfilePictureDialogComponent.submitDisabled = false;
  }

  uploadProfilePicture(blob: Blob | null): void {
    if (!this.user?.id) {
      return;
    }

    if (!blob) {
      // If there is no blob, update the user profile picture to null
      this.updateUserProfilePicture(null);
      return;
    }

    const formData: FormData = new FormData();
    formData.append('file', blob, 'profile-picture.png');
    formData.append('entity_type', DocumentEntityType.PROFILE_PICTURE);

    this.subscription.add(
      this.fileService.uploadFile(formData).subscribe({
        next: (res: AttachmentDocument) => {
          this.updateUserProfilePicture(res.id);
        },
        error: (error: HttpErrorResponse) => {
          this.uploadProfilePictureDialogComponent.submitDisabled = false;
          this.toastMessageService.showValidationErrors(error.error);
        },
      }),
    );
  }

  updateUserProfilePicture(profilePictureId: string | null): void {
    if (!this.user?.id) {
      return;
    }

    const payload = {
      profile_picture: profilePictureId ? { id: profilePictureId } : null,
    };
    this.userService.updateUserProfile(payload).subscribe({
      next: () => {
        this.uploadProfilePictureDialogComponent.closeDialog();
        this.refreshUserDataHandler.emit();
        this.toastMessageService.showSuccessToast('Profile picture updated successfully');
      },
      error: (error: HttpErrorResponse) => {
        this.uploadProfilePictureDialogComponent.submitDisabled = false;
        this.toastMessageService.showValidationErrors(error.error);
      },
    });
  }
}
