import { TokenService } from './../token/token.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_PAGE_SIZE } from '@constants/common.const';
import { EnvironmentService } from '@Services/environment/environment.service';
import { NotificationGroup } from '@shared/enums/notifications/notifications';
import {
  AppNotification,
  GroupNotification,
  NotificationResponse,
  NotificationState,
} from '@shared/interfaces/notification.interface';
import { startOfDay, subDays } from 'date-fns';
import {
  BehaviorSubject,
  catchError,
  interval,
  Observable,
  of,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly pollingInterval = this.environmentService.getNotificationPollInterval();

  private notificationsSubject = new BehaviorSubject<NotificationState>(this.getInitialState());

  private notifications$ = this.notificationsSubject.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly tokenService: TokenService,
    private readonly environmentService: EnvironmentService,
  ) {}

  getInitialState(): NotificationState {
    return {
      count: null,
      results: null,
      new_notification_count: null,
      isMarkRead: false,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    };
  }

  pollNotifications(): void {
    interval(this.pollingInterval)
      .pipe(
        startWith(0), // start immediately dont delay for first time
        switchMap(
          // switchMap is used to cancel the previous request if new request is made
          this.getNotificationHTTP.bind(this, true), // bind this to getNotificationHTTP
        ),
      )
      .subscribe((response) => {
        this.updateNotificationState(response);
      });
  }

  getNotifications(): Observable<NotificationState> {
    return this.notifications$;
  }

  transformToGroupedNotifications(notifications: AppNotification[]): GroupNotification[] {
    const groupedNotifications: GroupNotification[] = [
      {
        group: NotificationGroup.TODAY,
        notifications: [],
      },
      {
        group: NotificationGroup.YESTERDAY,
        notifications: [],
      },
      {
        group: NotificationGroup.OLDER,
        notifications: [],
      },
    ];

    notifications.forEach((notification) => {
      const notificationGroup = this.checkGroup(notification.created_date);

      switch (notificationGroup) {
        case NotificationGroup.TODAY:
          groupedNotifications[0].notifications.push(notification);
          break;
        case NotificationGroup.YESTERDAY:
          groupedNotifications[1].notifications.push(notification);
          break;
        case NotificationGroup.OLDER:
          groupedNotifications[2].notifications.push(notification);
          break;
      }
    });

    return groupedNotifications;
  }

  checkGroup(date: string): NotificationGroup {
    const currentDate = startOfDay(new Date(date)).getTime();
    const today = startOfDay(new Date()).getTime();
    const yesterday = subDays(today, 1).getTime();

    if (currentDate === today) {
      return NotificationGroup.TODAY;
    } else if (currentDate === yesterday) {
      return NotificationGroup.YESTERDAY;
    } else {
      return NotificationGroup.OLDER;
    }
  }

  setNewNotificationCount(count: number | null): void {
    this.notificationsSubject.next({
      ...this.notificationsSubject.value,
      new_notification_count: count,
    });
  }

  onNotificationPaginationChange(page: number, pageSize: number): Promise<void> {
    this.notificationsSubject.next({
      ...this.notificationsSubject.value,
      page: page,
      pageSize: pageSize,
    });
    return this.triggerNotificationGetManually();
  }

  triggerNotificationGetManually(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getNotificationHTTP(false)
        .pipe(
          take(1), // take only one value
          tap((response) => {
            // tap is used to perform side effect
            this.updateNotificationState(response);
            resolve();
          }),
          catchError((error: Error) => {
            console.error('Error in fetching notifications', error);
            reject(error);
            return of(null);
          }),
        )
        .subscribe();
    });
  }

  updateNotificationState(notification: NotificationResponse | null): void {
    if (!notification) {
      this.notificationsSubject.next(this.getInitialState());
      return;
    }

    this.notificationsSubject.next({
      ...this.notificationsSubject.value,
      count: notification.count,
      results: this.transformToGroupedNotifications(notification.results),
      new_notification_count: notification.new_notification_count,
      isMarkRead: notification.new_notification_count < 0,
    });
  }

  markNotificationsAsRead(): Observable<boolean> {
    return this.http.patch('notifications/', {}) as Observable<boolean>;
  }

  getNotificationHTTP(hideLoader = true): Observable<NotificationResponse | null> {
    if (this.tokenService.getToken()) {
      let params = new HttpParams();

      params = params.append('page', this.notificationsSubject.value.page.toString());
      params = params.append('page_size', this.notificationsSubject.value.pageSize.toString());

      return this.http.get(`notifications`, {
        headers: { 'hide-loader': hideLoader ? '1' : '' },
        params: params,
      }) as Observable<NotificationResponse>;
    } else {
      return of(null); // return empty array if no token
    }
  }
}
