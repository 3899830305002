<div class="archive-dialog" *ngIf="itemName; else elseBlock">
  <form [formGroup]="archiveForm" (ngSubmit)="onSubmit()">
    <div class="header d-flex justify-content-space-between align-items-center g-13">
      <div class="d-flex g-5 mt-15">
        <app-page-title [title]="headerText || ''" color="#000" [size]="18" />
        <app-page-title [title]="itemName || ''" [size]="18" />
      </div>
      <div class="mt-15">
        <p-button
          class="close-icon-button"
          icon="pi pi-times"
          [rounded]="true"
          [text]="true"
          severity="secondary"
          (click)="closeDialog()"
          (keyup.enter)="closeDialog()"
          tabindex="0"
        />
      </div>
    </div>
    <div>
      <p class="description">
        {{ descriptionText }}&nbsp;<strong>{{ itemName }}</strong
        >.
      </p>
      <div class="description-input mb-10">
        <app-input-template
          label="Reason for Archiving"
          for="claim"
          [error]="
            archiveForm.get('description')?.invalid && archiveForm.get('description')?.touched
          "
          [required]="true"
        >
          <ng-template #inputTemplate let-error="error">
            <textarea
              rows="3"
              cols="20"
              formControlName="description"
              pInputTextarea
              [autoResize]="true"
              placeholder="Type here..."
              [ngClass]="{ 'error-textarea': error }"
            ></textarea>
          </ng-template>
        </app-input-template>
      </div>
    </div>
    <div class="d-flex justify-content-end g-13">
      <p-button
        class="cancel-button"
        label="Cancel"
        [outlined]="true"
        [disabled]="isArchiveInProgress"
        (click)="closeDialog()"
        (keyup.enter)="closeDialog()"
        tabindex="0"
      />
      <p-button
        label="Archive"
        severity="primary"
        class="archive-button"
        [loading]="isArchiveInProgress"
        type="submit"
      />
    </div>
  </form>
</div>
<ng-template #elseBlock>
  <p-skeleton width="2rem" styleClass="mt-10 mb-10" />
  <p-skeleton styleClass="mt-5" />
  <p-skeleton width="10rem" styleClass="mt-5" height="4rem" />
  <div class="d-flex justify-content-end g-8 mt-10 mb-10">
    <p-skeleton width="5rem" styleClass="mt-5" />
    <p-skeleton width="5rem" styleClass="mt-5" />
  </div>
</ng-template>
