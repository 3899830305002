<p-dialog
  header="Change Picture"
  [modal]="true"
  [visible]="profilePictureDialogVisible"
  [style]="{ width: '35rem' }"
  [baseZIndex]="10000"
  [closable]="false"
>
  <div class="close-button">
    <p-button
      styleClass="close-icon-button"
      icon="pi pi-times"
      [rounded]="true"
      [text]="true"
      severity="secondary"
      (click)="closeDialog()"
      (keyup.enter)="closeDialog()"
      tabindex="0"
    />
  </div>
  <input
    #fileInput
    style="visibility: hidden; width: 0px; height: 0px"
    type="file"
    (change)="fileChangeEvent($event)"
    [accept]="acceptedExtensions"
  />
  <!-- View -->
  <div *ngIf="mode === displayModes.view">
    <div class="full-width d-flex justify-content-center align-items-center g-40">
      <div class="mt-0">
        <app-avatar
          [name]="fullName"
          [width]="200"
          [height]="200"
          borderColor="#2777BD"
          backgroundColor="#0E346B"
          [image]="hasExistingPictureRemoved ? null : (user?.profile_picture?.document ?? null)"
        />
      </div>

      <div class="buttons-container">
        <p-button
          styleClass="upload-picture-button"
          icon="pi pi-upload"
          label="Upload Picture&nbsp;&nbsp;"
          size="small"
          [outlined]="true"
          (click)="fileInput.click()"
          (keyup.enter)="fileInput.click()"
          tabindex="0"
        />
        @if (!hasExistingPictureRemoved && user?.profile_picture?.document) {
          <p-button
            styleClass="mt-10 remove-existing-picture-button"
            icon="pi pi-trash"
            label="Remove Picture"
            size="small"
            [outlined]="true"
            (click)="removeExistingProfilePicture()"
            (keyup.enter)="removeExistingProfilePicture()"
            tabindex="0"
          />
        }
      </div>
    </div>
  </div>
  <!-- View  End-->

  <!-- Upload -->
  <div
    *ngIf="mode === displayModes.upload"
    class="d-flex justify-content-center align-items-center g-25"
  >
    <div class="crop-picture-container">
      <div class="loader" *ngIf="isProcessingImage">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
      <div>
        <div
          class="image-cropper-container"
          [ngStyle]="{
            'opacity': isProcessingImage ? 0 : 1,
            'pointer-event': isProcessingImage ? 'none' : 'all',
          }"
        >
          @if (imageOutputFormat) {
            <image-cropper
              [imageChangedEvent]="newImageEvent"
              [maintainAspectRatio]="true"
              [aspectRatio]="1 / 1"
              [containWithinAspectRatio]="false"
              [onlyScaleDown]="true"
              [roundCropper]="true"
              [format]="imageOutputFormat"
              [cropperMinWidth]="100"
              [alignImage]="'center'"
              [allowMoveImage]="true"
              imageAltText="Select an image"
              backgroundColor="transparent"
              [(transform)]="transform"
              (imageCropped)="imageCropped($event)"
              (loadImageFailed)="loadImageFailed()"
              (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady()"
            ></image-cropper>
          }
        </div>
        <div *ngIf="!isProcessingImage" class="remove-container">
          <p-button
            styleClass="remove-button"
            icon="pi pi-trash"
            iconPos="left"
            [text]="true"
            label="Remove"
            (click)="removeImage()"
            (keyup.enter)="removeImage()"
            [disabled]="!newImageEvent"
            tabindex="0"
          ></p-button>
        </div>
      </div>
    </div>
    <div [ngStyle]="{ width: '200px' }">
      <div class="d-flex justify-content-center align-items-center g-10">
        <p-button
          class="zoom-out"
          styleClass="zoom-buttons"
          icon="pi pi-minus"
          [text]="true"
          [outlined]="true"
          (click)="zoomOut()"
          (keyup.enter)="zoomOut()"
          tabindex="0"
          [disabled]="zoomLevel === 0 || isProcessingImage"
        />
        <div style="flex: 1">
          <p-slider
            class="zoom-slider"
            (onChange)="onZoomLevelChange($event)"
            [ngModel]="zoomLevel"
            [min]="0"
            [max]="20"
            [step]="1"
            [disabled]="isProcessingImage"
          />
        </div>
        <p-button
          class="zoom-in"
          styleClass="zoom-buttons"
          icon="pi pi-plus"
          [text]="true"
          [outlined]="true"
          (click)="zoomIn()"
          (keyup.enter)="zoomIn()"
          [disabled]="zoomLevel === 20 || isProcessingImage"
          tabindex="0"
        />
      </div>
    </div>
  </div>
  <!-- Upload End -->
  <div class="d-flex g-13 justify-content-end">
    <p-button
      styleClass="cancel-button"
      label="Cancel"
      [outlined]="true"
      (onClick)="closeDialog()"
      (keyup.enter)="closeDialog()"
      tabindex="0"
    />
    <p-button
      styleClass="save-button"
      label="Save"
      (onClick)="saveUserProfile()"
      (keyup.enter)="saveUserProfile()"
      [disabled]="
        !(newImageEvent || hasExistingPictureRemoved) || isProcessingImage || submitDisabled
      "
      tabindex="0"
    />
  </div>
</p-dialog>
