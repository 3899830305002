<div class="confirmation-dialog">
  <div class="header d-flex justify-content-space-between align-items-center g-13">
    <div class="d-flex g-5 mt-15">
      <app-page-title [title]="title" color="#000" [size]="18" />
    </div>
    <div class="mt-15">
      <p-button
        class="close-icon-button"
        icon="pi pi-times"
        [rounded]="true"
        [text]="true"
        severity="secondary"
        (click)="closeDialog(false)"
        (keyup.enter)="closeDialog(false)"
        tabindex="0"
      />
    </div>
  </div>
  <div>
    <p class="description">
      {{ description }}
    </p>
    <ng-container *ngIf="subDescription">
      <p>{{ subDescription }}</p>
    </ng-container>
  </div>
  <div class="d-flex justify-content-end g-13">
    <p-button
      class="cancel-button"
      label="No"
      [outlined]="true"
      (click)="closeDialog(false)"
      (keyup.enter)="closeDialog(false)"
      tabindex="0"
    />
    <p-button
      label="Yes"
      severity="primary"
      class="confirm-button"
      type="button"
      (click)="closeDialog(true)"
      (keyup.enter)="closeDialog(true)"
      tabindex="0"
    />
  </div>
</div>
