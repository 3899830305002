<div class="margin-side-bar">
  <div class="header-container">
    <div class="mt-20">
      <app-back-button />
    </div>
    <div class="mt-20">
      <app-page-title title="Add Project" />
    </div>
  </div>
  @if (hasCreateProjectPermission) {
    <div class="mt-20">
      <app-project-form
        [regions]="regions"
        submitButtonLabel="Save & Close"
        (emitOnSubmit)="createNewProject($event)"
        (emitOnCancel)="cancel()"
      />
    </div>
  }
</div>
