import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
  selector: 'app-location-widget',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './location-widget.component.html',
  styleUrl: './location-widget.component.scss',
})
export class LocationWidgetComponent {
  @Input() cityName: string;
  @Input() countryName: string;
  @Input() set appId(id: string) {
    if (!id) return;
    this.apiKey = id;
    this.loadGoogleMaps();
  }
  @Input() set coordinates(cord: string) {
    if (!cord) return;
    this.lat = Number(cord.split(',')[0]);
    this.long = Number(cord.split(',')[1]);
    this.loadGoogleMaps();
  }

  lat: number;
  long: number;
  apiKey: string;

  loadGoogleMaps() {
    if (!this.lat || !this.long || !this.apiKey) return;

    const loader = new Loader({
      apiKey: this.apiKey,
      version: 'weekly',
    });

    loader.load()?.then(async () => {
      const { Map: LocationMap } = (await google.maps.importLibrary(
        'maps',
      )) as google.maps.MapsLibrary;
      const map = new LocationMap(document.getElementById('map') as HTMLElement, {
        center: { lat: this.lat, lng: this.long },
        zoom: 5,
        fullscreenControl: false,
      });

      const marker = new google.maps.Marker({
        map: map,
        position: { lat: this.lat, lng: this.long },
      });
      console.log(!!marker, 'created marker');
    });
  }

  openGoogleMaps(): void {
    if (this.lat && this.long) {
      const url = `https://www.google.com/maps?q=${this.lat},${this.long}`;
      window.open(url, '_blank');
    }
  }
}
