import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { AppNotificationType } from '@shared/enums/notifications/notifications';
import { AppNotification } from '@shared/interfaces/notification.interface';
import { TimeAgoPipe } from '@shared/pipes/time-ago/time-ago.pipe';

@Component({
  selector: 'app-notification-card',
  standalone: true,
  imports: [AvatarComponent, RouterModule, CommonModule, TimeAgoPipe],
  templateUrl: './notification-card.component.html',
  styleUrl: './notification-card.component.scss',
})
export class NotificationCardComponent {
  @Input() notification: AppNotification;

  AppNotificationType = AppNotificationType;
}
