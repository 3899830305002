import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { NotificationTableComponent } from './notification-table/notification-table.component';
import { GroupNotification, NotificationState } from '@shared/interfaces/notification.interface';
import { NotificationsService } from '@Services/notifications/notifications.service';
import { Subscription } from 'rxjs';
import { PaginatorModule, PaginatorState } from 'primeng/paginator';
import { DEFAULT_PAGE_SIZE } from '@constants/common.const';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [PageTitleComponent, NotificationTableComponent, PaginatorModule, CommonModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  notificationStateData: NotificationState | null;

  count = 0;

  from = 0;

  pageSize = 0;

  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly toastMessageService: ToastMessageService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      // subscribe to the notifications service to get the new notifications
      this.notificationsService.getNotifications().subscribe((notifications) => {
        this.notificationStateData = notifications;
        this.count = notifications.count ?? 0;
        this.pageSize = notifications.pageSize;
      }),
    );

    // trigger the notification get manually on notification page open
    this.notificationsService
      .triggerNotificationGetManually()
      .then(() => {
        this.markNotificationsRead();
      })
      .catch((error: HttpErrorResponse) => {
        this.toastMessageService.showValidationErrors(error.error);
      });
  }

  markNotificationsRead(): void {
    if (
      this.notificationStateData?.new_notification_count &&
      this.notificationStateData?.new_notification_count > 0
    ) {
      this.subscription.add(
        this.notificationsService.markNotificationsAsRead().subscribe(() => {
          this.notificationsService.setNewNotificationCount(null);
        }),
      );
    }
  }

  get groupedNotifications(): GroupNotification[] {
    if (!this.notificationStateData?.results) {
      return [];
    }
    return this.notificationStateData.results;
  }

  onPageChange(state: PaginatorState): void {
    this.notificationsService
      .onNotificationPaginationChange(
        state.page ? state.page + 1 : 1,
        state.rows ?? DEFAULT_PAGE_SIZE,
      )
      .then(() => {
        this.from = state.first ?? 1;
      });
  }

  get showEmptyNotification(): boolean {
    if (this.notificationStateData?.results?.length === 0) {
      return true;
    }
    return this.notificationStateData?.count === 0;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
