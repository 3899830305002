import { Component, OnDestroy, OnInit } from '@angular/core';
import { SideBarComponent } from '../side-bar/side-bar.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { CommonModule } from '@angular/common';
import { UserService } from '@Services/user/user.service';
import { LoggedInUserService } from '@Services/user/logged-in-user.service';
import { TokenService } from '@Services/token/token.service';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@Services/notifications/notifications.service';
import { Subscription } from 'rxjs';
import { User } from '@shared/interfaces';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterOutlet, SideBarComponent, HeaderComponent, CommonModule],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
/**
 * Represents the layout component of the application.
 * This component is responsible for managing the layout and behavior of the application's UI.
 */
export class LayoutComponent implements OnInit, OnDestroy {
  sidebarExpand = false;

  latestProfileDataFetched = false;

  // this flag is used to skip the profile rest call when the user is navigated to error pages
  skipProfileRestCall = false; // default value is false to load profile data on login or refresh

  subscriptions: Subscription;

  user: User | null = null;

  constructor(
    public readonly tokenService: TokenService,
    public readonly userService: UserService,
    public readonly activatedRoute: ActivatedRoute,
    public readonly loggedInUserService: LoggedInUserService,
    public readonly toastMessageService: ToastMessageService,
    private readonly notificationService: NotificationsService,
    private readonly router: Router,
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.loggedInUserService.getLoggedInUser().subscribe((user) => {
        this.user = user;
      }),
    );

    this.skipProfileRestCall = this.activatedRoute.snapshot.data['skipProfileRestCall'] ?? false;
    this.parseAndSaveAuthToken(this.activatedRoute.snapshot.queryParams);
    this.notificationService.pollNotifications();
  }

  parseAndSaveAuthToken(data: Record<string, string>) {
    if (!data['access_token'] || !data['refresh_token']) {
      this.fetchUserProfileData();
      return;
    }
    this.tokenService.saveAuthTokens(data['access_token'], data['refresh_token']);
    this.fetchUserProfileData();
    // remove the query params of access_token and refresh_token from the url
    this.router.navigate([''], {
      relativeTo: this.activatedRoute,
      queryParams: {},
      queryParamsHandling: null,
    });
  }

  fetchUserProfileData() {
    if (this.skipProfileRestCall) {
      this.latestProfileDataFetched = true;
      return;
    }
    this.userService
      .fetchUserProfileData()
      .then(() => {
        this.latestProfileDataFetched = true;
      })
      .catch((httpError: HttpErrorResponse) => {
        this.toastMessageService.showValidationErrors(
          httpError.error,
          httpError?.error?.error_message,
        );
      });
  }

  public onSidebarHover(state: boolean) {
    this.sidebarExpand = state;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
