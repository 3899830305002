/**
 * Represents the UserProfileComponent class.
 * This component is responsible for displaying and managing the user profile.
 * It includes a form for editing user details and a profile picture component.
 * @class
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { MainProfilePictureComponent } from './main-profile-picture/main-profile-picture.component';
import { UserProfileFormComponent } from './user-profile-form/user-profile-form.component';
import { UserService } from '@Services/user/user.service';
import { User } from '@shared/interfaces';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { filter, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { get } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { BackButtonComponent } from '@shared/components/back-button/back-button.component';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    PageTitleComponent,
    MainProfilePictureComponent,
    UserProfileFormComponent,
    FormsModule,
    ReactiveFormsModule,
    BackButtonComponent,
  ],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
})
export class UserProfileComponent implements OnInit, OnDestroy {
  profileForm: FormGroup;

  subscription: Subscription;

  userDetails: User | null = null;

  formPopulate = false;

  isMyProfile = true;

  constructor(
    private readonly userService: UserService,
    private readonly toastMessageService: ToastMessageService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit() {
    this.initializeForm();
    this.getProfileDetails();
    this.observeFormChanges();
    this.subscribeNavigationChange();
  }

  subscribeNavigationChange(): void {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd), // Filter only NavigationEnd events
        )
        .subscribe(() => {
          this.getProfileDetails();
        }),
    );
  }

  initializeForm(): void {
    this.profileForm = new FormGroup({
      name: new FormControl(
        {
          value: null,
          disabled: true,
        },
        [Validators.required],
      ),
      email: new FormControl(
        {
          value: null,
          disabled: true,
        },
        [Validators.required],
      ),
      role: new FormControl(
        {
          value: null,
          disabled: true,
        },
        [Validators.required],
      ),
      profilePicture: new FormControl(null, []),
      notificationsEnabled: new FormControl(null, [Validators.required]),
    });
  }

  observeFormChanges(): void {
    this.subscription.add(
      this.profileForm.get('notificationsEnabled')?.valueChanges.subscribe((value: boolean) => {
        if (!this.formPopulate) {
          return;
        }
        this.subscription.add(
          this.userService.updateUserProfile({ notifications_enabled: value }).subscribe({
            next: () => {
              this.toastMessageService.showSuccessToast('Profile updated successfully');
              this.userService.fetchUserProfileData();
            },
            error: (error: HttpErrorResponse) => {
              this.toastMessageService.showValidationErrors(error.error);
            },
          }),
        );
      }),
    );
  }

  getProfileDetails(): void {
    const queryParamsUserId = get(this.activatedRoute, 'snapshot.queryParams.userId');
    // if user id is same of logged in user then it is my profile
    if (queryParamsUserId && queryParamsUserId !== this.userService.getUserData()?.id) {
      this.isMyProfile = false;
    } else {
      this.isMyProfile = true;
    }
    const id = queryParamsUserId ?? this.userService.getUserData()?.id;
    this.subscription.add(
      this.userService.getUserDetails(id).subscribe({
        next: (data: User) => {
          this.formPopulate = false;
          this.userDetails = data;
        },
        error: (httpError) => {
          this.toastMessageService.showValidationErrors(httpError.error);
        },
      }),
    );
  }

  refreshUserData(): void {
    this.userService.fetchUserProfileData();
    this.getProfileDetails();
  }

  onFormPopulateComplete(): void {
    this.formPopulate = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
