<div>
  <div class="mt-20 ml-20">
    <app-back-button />
  </div>
  <div class="mt-20 mb-10 ml-20">
    <app-page-title title="Profile" fontWeight="600" [size]="20" />
  </div>
  <form [formGroup]="profileForm">
    <div class="d-flex justify-content-start flex-wrap">
      <div class="profile-picture-container">
        <app-main-profile-picture
          [user]="userDetails"
          [isMyProfile]="isMyProfile"
          (refreshUserDataHandler)="refreshUserData()"
        />
      </div>
      <div class="profile-form">
        <app-user-profile-form
          [user]="userDetails"
          [isMyProfile]="isMyProfile"
          (onFormPopulateCompleteHandler)="onFormPopulateComplete()"
        />
      </div>
    </div>
  </form>
</div>
