import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  getApiURL(): string {
    return environment.API_URL;
  }

  getEnv() {
    return environment.ENV;
  }

  getNotificationPollInterval() {
    return environment.NOTIFICATION_POLL_INTERVAL;
  }
}
