import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { ArchiveDialogComponent } from '@shared/components/dialogs/archive-dialog/archive-dialog.component';
import {
  ArchiveDialogData,
  ArchiveDialogServiceData,
} from '@shared/interfaces/archive-dialog.interface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArchiveDialogService {
  subscription: Subscription;

  ref: DynamicDialogRef | undefined;

  /**
   * Creates an instance of ArchiveDialogService.
   * @param {DialogService} dialogService - The dialog service.
   * @param {ToastMessageService} toastMessageService - The toast message service.
   * @param {HttpClient} httpClient - The HTTP client.
   */
  constructor(
    private dialogService: DialogService,
    private toastMessageService: ToastMessageService,
    private httpClient: HttpClient,
  ) {
    this.subscription = new Subscription();
  }

  /**
   * Opens the archive dialog.
   * @param {ArchiveDialogServiceData} archiveDetails - The archive dialog data.
   * @param {() => void} successCB - The success callback function.
   * @returns {void}
   */
  openArchiveDialog(archiveDetails: ArchiveDialogServiceData, successCB: () => void): void {
    this.ref = this.dialogService.open(ArchiveDialogComponent, {
      showHeader: false,
      closable: false,
      width: '50%',
      style: { 'max-width': '500px', 'min-width': '300px' },
      data: {
        headerText: archiveDetails.headerText,
        itemName: archiveDetails.name,
        descriptionText: archiveDetails.description,
        archiveCallback: this.getArchiveCallback(successCB, archiveDetails),
      } as ArchiveDialogData,
    });
  }

  /**
   * Returns the archive callback function.
   * @param {() => void} successCB - The success callback function.
   * @param {ArchiveDialogServiceData} archiveDetails - The archive dialog data.
   * @returns {(reason: string) => Promise<void>} - The archive callback function.
   */
  getArchiveCallback(
    successCB: () => void,
    archiveDetails: ArchiveDialogServiceData,
  ): (reason: string) => Promise<void> {
    return (reason: string) => {
      return new Promise<void>((resolve, reject) => {
        this.subscription.add(
          this.archiveData(archiveDetails.path, { archive_reason: reason }).subscribe({
            next: () => {
              this.toastMessageService.showSuccessToast(
                `"${archiveDetails.name}" successfully archived.`,
              );
              successCB(); // to inform parent component that the archive was successful
              resolve(); // to inform the dialog that the archive was successful
            },
            error: (httpError: HttpErrorResponse) => {
              this.toastMessageService.showValidationErrors(httpError.error);
              reject(new Error('')); // to inform the dialog that the archive was unsuccessful
            },
          }),
        );
      });
    };
  }

  /**
   * Archives the data.
   * @param {string} path - The path to the data.
   * @param {Record<string, string | number>} body - The request body.
   * @returns {Observable<unknown>} - The HTTP response observable.
   */
  archiveData(path: string, body: Record<string, string | number>): Observable<unknown> {
    return this.httpClient.patch(path, body);
  }
}
