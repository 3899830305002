<div class="sidebar" [ngClass]="{ 'open': sidebarExpand }">
  <div class="sidebar-header">
    <div class="logo-close">
      <img src="assets/logos/re-logo-white-mini.svg" alt="logo" />
    </div>

    <div class="logo-open">
      <img src="assets/logos/re-logo-white.svg" alt="logo" width="150px" />
    </div>
  </div>
  <div class="sidebar-body">
    <ul class="sidebar-menu">
      <li *ngFor="let item of SIDEBAR_MENU_ITEMS">
        @if (checkVisibilityByPermission(item.Permissions || null)) {
          <a [routerLink]="item.route" [ngClass]="{ 'active': isActive(item.route) }">
            <img
              class="sidebar-menu-icon"
              [src]="isActive(item.route) ? item.activeIcon : item.icon"
              [alt]="item.alt"
            />
            <span class="sidebar-menu-text">{{ item.text }}</span>
          </a>
        }
      </li>
    </ul>
    <div>
      <ul class="sidebar-menu footer">
        @for (item of SIDEBAR_BOTTOM_MENU; track item; let i = $index) {
          <li>
            @if (checkVisibilityByPermission(item.Permissions || null)) {
              <a [routerLink]="item.route" [ngClass]="{ 'active': isActive(item.route) }">
                <img
                  class="sidebar-menu-icon"
                  [src]="isActive(item.route) ? item.activeIcon : item.icon"
                  [alt]="item.alt"
                />
                <span class="sidebar-menu-text">{{ item.text }}</span>
              </a>
            }
          </li>
        }
        <li>
          <a (click)="handleLogout()" (keyup.enter)="handleLogout()" tabindex="0">
            <img class="sidebar-menu-icon" src="assets/icons/sign-in.svg" alt="logout" />
            <span class="sidebar-menu-text">Logout</span>
          </a>
        </li>
      </ul>
      @if (sidebarExpand) {
        <div class="version-number">
          <span>Version {{ version }}</span>
        </div>
      }
    </div>
  </div>
</div>
