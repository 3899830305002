<div class="project-list-container margin-side-bar">
  <div
    class="header-container d-flex justify-content-space-between align-items-end title-container pb-20"
  >
    <div class="d-flex g-13">
      <app-page-title [title]="'User Roles'" />
    </div>
  </div>
  <div>
    <app-table
      [tableConfig]="usersTableConfig"
      [userGroups]="userRoles"
      (tableSignals)="handleTableSignals($event)"
    ></app-table>
  </div>
</div>
