import { DataTableConfig, TableActions, TableColumnDisplay } from '../shared/interfaces/table';
import { ProjectDetailsResponse } from '@shared/interfaces';
import { format } from 'date-fns';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { PrimeIcons } from 'primeng/api';

export const ProjectsArchiveTableConfig: DataTableConfig<ProjectDetailsResponse> = {
  endPoint: 'assetmanagement/projects/',
  showCheckboxes: true,
  sortBy: 'name',
  actionItems: [
    {
      label: 'Delete',
      value: TableActions.deleteItem,
      permission: AllGroupPermissions.DELETE_PROJECT,
      icon: PrimeIcons.TRASH,
    },
  ],
  fixedWidth: true,
  fixedMinWidth: '1300px',
  formatData: (data: ProjectDetailsResponse[]) => {
    return (data || []).map(
      (item) =>
        ({
          ...item,
          cod_date: format(item.cod_date, 'MM-dd-yyyy'),
        }) as unknown as ProjectDetailsResponse,
    );
  },
  additionalParams: [
    {
      key: 'is_archive',
      value: true,
    },
  ],
  columns: [
    {
      title: 'Project ID',
      name: 'code',
      displayType: TableColumnDisplay.value,
      width: '9%',
      hasColumnFilters: false,
      sortable: false,
    },
    {
      title: 'Site',
      name: 'code',
      nestedProperty: 'site',
      displayType: TableColumnDisplay.nestedValue,
      width: '8%',
      hasColumnFilters: false,
      sortable: false,
    },
    {
      title: 'Name',
      name: 'name',
      action: TableActions.viewProjectDetails,
      displayType: TableColumnDisplay.link,
      width: '30%',
      sortable: false,
      hasColumnFilters: false,
    },
    {
      title: 'Country',
      name: 'name',
      displayType: TableColumnDisplay.countryFlag,
      nestedProperty: 'country',
      width: '11%',
      hasColumnFilters: false,
      sortable: false,
    },
    {
      title: 'City',
      name: 'name',
      displayType: TableColumnDisplay.nestedValue,
      nestedProperty: 'city',
      hasColumnFilters: false,
      sortable: false,
      width: '11%',
    },
    {
      title: 'Region',
      name: 'name',
      displayType: TableColumnDisplay.nestedValue,
      nestedProperty: 'region',
      hasColumnFilters: false,
      sortable: false,
      width: '10%',
    },
    {
      title: 'Operational Date',
      name: 'cod_date',
      displayType: TableColumnDisplay.value,
      sortable: false,
      hasColumnFilters: false,
      width: '13%',
    },
    {
      title: 'Technology',
      name: 'technology',
      width: '10%',
      displayType: TableColumnDisplay.value,
      hasColumnFilters: false,
      sortable: false,
    },
    {
      title: 'Capacity (MW)',
      name: 'capacity_ac',
      width: '10%',
      displayType: TableColumnDisplay.value,
      hasColumnFilters: false,
      sortable: false,
    },
    {
      title: 'Status',
      name: 'status',
      width: '8%',
      displayType: TableColumnDisplay.status,
      hasColumnFilters: false,
      sortable: false,
    },
  ],
};
