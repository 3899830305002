import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { TokenService } from '@Services/token/token.service';
import { AuthToken } from '@shared/interfaces/common.interface';
import { Router } from '@angular/router';
import { UserService } from '@Services/user/user.service';
import { ByPassUrls } from '@constants/byPassUrls.const';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null,
  );

  constructor(
    private readonly tokenService: TokenService,
    private readonly router: Router,
    private readonly userService: UserService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.tokenService.getToken();
    const shouldByPass = ByPassUrls.some((url) => request.url.includes(url));
    if (
      token &&
      !shouldByPass && //do not attach token if it is in list of by pass urls
      !request.url.includes('refresh') //do not attach token if it is a refresh token request
    ) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.InternalServerError) {
          this.router.navigate(['/error/500']);
          return throwError(() => ({ status: error.status, error: {} }));
        } else if (error.status === HttpStatusCode.Forbidden) {
          this.router.navigate(['/error/403']);
          return throwError(() => ({ status: error.status, error: {} }));
        } else if (error.status === HttpStatusCode.NotFound) {
          this.router.navigate(['/error/404']);
          return throwError(() => ({ status: error.status, error: {} }));
        }

        // On encountering unauthorize error on refresh token we should clear the data and navigate to login page.
        if (
          request.url.includes('token/refresh/') &&
          error.status === HttpStatusCode.Unauthorized &&
          !shouldByPass // do not clear data if it is in list of by pass urls
        ) {
          this.clearDataOnTokenRefreshFail();
        } else if (
          error.status === HttpStatusCode.Unauthorized &&
          !shouldByPass // do not clear data if it is in list of by pass urls
        ) {
          return this.handleUnAuthorizeError(request, next);
        }
        return throwError(() => error);
      }),
    );
  }

  private addToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handleUnAuthorizeError(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.tokenService.refreshAuthToken().pipe(
        switchMap((authToken: AuthToken) => {
          if (authToken && authToken.token && authToken.refreshToken) {
            this.refreshTokenSubject.next(authToken.token);
            this.isRefreshing = false;
            return next.handle(this.addToken(request, authToken.token));
          }
          this.clearDataOnTokenRefreshFail();
          return throwError(() => new Error('Token refresh failed.'));
        }),
        catchError((error) => {
          // Handle refresh token errors, logout user or navigate to login page.
          this.clearDataOnTokenRefreshFail();
          return throwError(() => new Error(error.error_message ?? 'Token refresh failed.'));
        }),
      );
    } else {
      // Wait while refreshing, then retry with new token.
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null), // wait until token is available
        take(1), // take only one value
        switchMap((token) => {
          // retry with new token
          return next.handle(this.addToken(request, token as string));
        }),
      );
    }
  }

  clearDataOnTokenRefreshFail() {
    this.isRefreshing = false;
    this.tokenService.removeAuthTokens();
    this.userService.clearUserData();
    this.router.navigate(['/error/401']);
  }
}
