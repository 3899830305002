<div *ngIf="notificationGroupedData">
  <p-dataView #dv [value]="notificationGroupedData">
    <ng-template pTemplate="list" let-notificationGroupedData>
      @for (groupedNotifications of notificationGroupedData; track groupedNotifications) {
        <div [class]="'pt-10 grouped-notification-' + groupedNotifications.group | lowercase">
          <div class="notification-group-title-container">
            <app-page-title [title]="groupedNotifications.group" [size]="14" color="#000" />
          </div>
          <div class="notification-list">
            @for (notification of groupedNotifications.notifications; track notification) {
              <app-notification-card [notification]="notification" />
            }
          </div>

          @if (groupedNotifications.notifications.length === 0) {
            <div class="notification-group-title-container pt-10">
              <p class="no-notification">
                No notifications for {{ groupedNotifications.group | lowercase }}
              </p>
            </div>
          }
        </div>
      }
    </ng-template>
  </p-dataView>
</div>
