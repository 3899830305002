import { Component } from '@angular/core';
import { InvoiceListingComponent } from '../../project/pages/project-details-page-layout/invoice-tab-content/invoice-listing/invoice-listing.component';
import { GlobalProposalTableConfig } from '@constants/globalProposalsTableConfig.const';

@Component({
  selector: 'app-global-invoice-list',
  standalone: true,
  imports: [InvoiceListingComponent],
  templateUrl: './global-invoice-list.component.html',
})
export class GlobalInvoiceListComponent {
  globalProposalTableConfig = GlobalProposalTableConfig;
}
