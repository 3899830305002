import { Pipe, PipeTransform } from '@angular/core';
import { timeAgo } from '@shared/utils/common-utils';

@Pipe({
  name: 'timeAgo',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date | string): unknown {
    return timeAgo(value);
  }
}
