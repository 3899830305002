<div class="mt-20 mb-20">
  <app-page-title [title]="'Notifications'" [size]="24"></app-page-title>
</div>
<div *ngIf="!showEmptyNotification">
  <div>
    <app-notification-table [notificationGroupedData]="groupedNotifications" />
    <!-- pagination -->
    <div
      class="flex align-items-center justify-content-end"
      style="display: flex; align-items: center; justify-content: flex-end"
    >
      <div class="pagination-text">
        Showing {{ from + 1 }} to {{ count > from + pageSize ? from + pageSize : count }} of
        {{ count }} entries
      </div>
      <div>
        <div class="card" style="display: flex; justify-content: flex-end">
          <p-paginator
            locale="en-US"
            (onPageChange)="onPageChange($event)"
            [first]="from"
            [rows]="pageSize"
            [totalRecords]="count"
            [rowsPerPageOptions]="[25, 50, 75, 100]"
          />
        </div>
      </div>
      <!-- /pagination -->
    </div>
  </div>
</div>
<div *ngIf="showEmptyNotification">
  <div class="container empty-notification-container">
    <div class="image-container mt-10">
      <i class="pi pi-bell" style="font-size: 50px"></i>
    </div>
    <div class="mt-10 mb-10">
      <app-page-title [title]="'No notifications right now.'" [size]="18"></app-page-title>
    </div>
    <p class="empty-state-description mt-10">
      It looks like you haven't received any notifications yet.
    </p>
    <p class="empty-state-description">Stay tuned! We’ll update you with new alert here.</p>
  </div>
</div>
