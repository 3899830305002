import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';

export enum SideBarItemKey {
  DASHBOARD = 'DASHBOARD',
  PORTFOLIO = 'PORTFOLIO',
  PROJECTS = 'PROJECTS',
  TASKS = 'TASKS',
  CLAIMS = 'CLAIMS',
  FINANCIAL_MANAGEMENT = 'FINANCIAL_MANAGEMENT',
  PERFORMANCE_REPORTING = 'PERFORMANCE_REPORTING',
  DOCUMENTS = 'DOCUMENTS',
  LOGOUT = 'LOGOUT',
  SETTINGS = 'SETTINGS',
  CONTACTS = 'CONTACTS',
}

export const SIDEBAR_BOTTOM_MENU = [
  {
    icon: 'assets/icons/users.svg',
    activeIcon: 'assets/icons/users-white.svg',
    alt: 'tasks',
    text: 'User Roles',
    route: '/users',
    Permissions: AllGroupPermissions.VIEW_USER,
  },
];

export const MENU_ITEMS = [
  {
    icon: 'assets/icons/projects.svg',
    activeIcon: 'assets/icons/projects-white.svg',
    alt: 'projects',
    text: 'Projects',
    route: '/project',
    Permissions: AllGroupPermissions.VIEW_PROJECT,
  },
  {
    icon: 'assets/icons/tasks.svg',
    activeIcon: 'assets/icons/tasks-white.svg',
    alt: 'tasks',
    text: 'Tasks',
    route: '/tasks',
    Permissions: AllGroupPermissions.VIEW_TASK,
  },
  {
    icon: 'assets/icons/claims.svg',
    activeIcon: 'assets/icons/claims-white.svg',
    alt: 'claims',
    text: 'Claims',
    route: '/claims',
    Permissions: AllGroupPermissions.VIEW_CLAIM,
  },
  {
    icon: 'assets/icons/invoices.svg',
    activeIcon: 'assets/icons/invoices-white.svg',
    alt: 'invoices',
    text: 'Invoices',
    route: '/invoices',
    Permissions: AllGroupPermissions.VIEW_INVOICE,
  },
];
