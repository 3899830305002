<div *ngIf="profileForm" class="full-width profile-form">
  <div class="custom-form full-width" [formGroup]="profileForm">
    <div class="pt-10 full-width">
      <ng-container
        *ngTemplateOutlet="header; context: { title: 'Personal Information' }"
      ></ng-container>
    </div>
    <div class="mt-15">
      <!-- Name -->
      <div class="form-input">
        <app-input-field
          [id]="'user-name'"
          name="user-name"
          label="Name"
          [required]="true"
          formControlName="name"
          placeholder="Name"
          [error]="profileForm.get('name')?.invalid && profileForm.get('name')?.touched"
        />
      </div>

      <!-- Email -->
      <div class="form-input">
        <app-input-field
          [id]="'user-email'"
          name="user-email"
          label="Email"
          [required]="true"
          formControlName="email"
          placeholder="Email"
          [error]="profileForm.get('email')?.invalid && profileForm.get('email')?.touched"
        />
      </div>

      <!-- Role -->
      <div class="form-input">
        <app-input-field
          [id]="'user-role'"
          name="user-role"
          label="System Role"
          [required]="true"
          formControlName="role"
          placeholder="Role"
          [error]="profileForm.get('role')?.invalid && profileForm.get('role')?.touched"
        />
      </div>
    </div>

    <div class="pt-10 mt-20 full-width">
      <ng-container
        *ngTemplateOutlet="header; context: { title: 'Notification Settings' }"
      ></ng-container>
    </div>
    <div class="mt-15">
      <!-- Is Vat -->
      <div class="form-input d-flex g-13 mt-25 notification-settings">
        <p-inputSwitch
          formControlName="notificationsEnabled"
          [readonly]="!isMyProfile"
          id="enable-notification"
        />
        <div class="label">Enable Notifications</div>
      </div>
    </div>
  </div>

  <ng-template #header let-title="title">
    <app-page-title [title]="title" fontWeight="600" [size]="14" color="#000" />
    <div class="line mt-15"></div>
  </ng-template>
</div>
