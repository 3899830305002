<div class="d-flex align-items-center g-13">
  <!-- Use the template -->
  <ng-container *ngTemplateOutlet="userBasicInfo"></ng-container>
  <div>
    <p-button
      icon="pi"
      (onClick)="op.toggle($event)"
      (keyup.enter)="op.toggle($event)"
      tabindex="0"
      [text]="true"
      [rounded]="true"
    >
      <app-avatar
        [image]="user?.profile_picture?.document || null"
        [name]="fullName"
        [height]="40"
        [width]="40"
      />
    </p-button>

    <p-overlayPanel #op>
      <!-- Use the template -->
      <ng-container *ngTemplateOutlet="profileMenu; context: { overlayPanel: op }"></ng-container>
    </p-overlayPanel>
  </div>
</div>

<ng-template #userBasicInfo let-showLink="showLink" let-overlayPanel="overlayPanel">
  <div>
    <div
      class="username"
      [ngStyle]="{
        'align-items': showLink ? 'flex-start' : 'flex-end',
      }"
    >
      <p>{{ fullName }}</p>
      <span>{{ groupName }}</span>
      @if (showLink) {
        <a [routerLink]="['/user/profile']" class="profile-link" (click)="overlayPanel.hide()">
          <span>View Profile</span>&nbsp;&nbsp;&nbsp;
          <i class="pi pi-arrow-right" style="font-size: 10px"> </i>
        </a>
      }
    </div>
  </div>
</ng-template>

<ng-template #profileMenu let-overlayPanel="overlayPanel">
  <div class="d-flex align-items-center justify-content-center g-13 profile-item">
    <!-- Use the template -->
    <app-avatar
      [image]="user?.profile_picture?.document"
      [name]="fullName"
      [height]="40"
      [width]="40"
    />
    <ng-container
      *ngTemplateOutlet="userBasicInfo; context: { showLink: true, overlayPanel: overlayPanel }"
    ></ng-container>
  </div>
</ng-template>
