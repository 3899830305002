import { Component } from '@angular/core';
import { TaskListComponent } from '../../../project/pages/project-details-page-layout/task-tab-content/task-list/task-list.component';

@Component({
  selector: 'app-global-task-list',
  standalone: true,
  imports: [TaskListComponent],
  templateUrl: './global-task-list.component.html',
})
export class GlobalTaskListComponent {}
