import { Component } from '@angular/core';
import { ClaimListComponent } from '../../../project/pages/project-details-page-layout/claims-tab-content/claim-list/claim-list.component';
import { GlobalClaimsTableConfig } from '@constants/globalClaimsTableConfig.const';

@Component({
  selector: 'app-global-claims-list',
  standalone: true,
  imports: [ClaimListComponent],
  templateUrl: './global-claims-list.component.html',
})
export class GlobalClaimsListComponent {
  globalClaimsTableConfig = { ...GlobalClaimsTableConfig };
}
