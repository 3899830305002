import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ScrollerModule } from 'primeng/scroller';
import { SidebarModule } from 'primeng/sidebar';
import { MENU_ITEMS, SIDEBAR_BOTTOM_MENU } from './constant';
import { ConfirmationDialogService } from '@Services/dialogs/confirmation-dialog/confirmation-dialog.service';
import { AuthService } from '../../auth/services/auth/auth.service';
import { ToastMessageService } from '@Services/toast-message/toast-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { isArray } from 'lodash';
import { PermissionService } from '@Services/permission/permission.service';
import { environment } from '@environment/environment';

/**
 * Represents the SideBarComponent which displays the sidebar menu.
 */
@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [SidebarModule, RouterModule, CommonModule, ScrollerModule],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss',
})
/**
 * Represents the sidebar component.
 */
export class SideBarComponent {
  /**
   * Indicates whether the sidebar is expanded or collapsed.
   */
  @Input() sidebarExpand = false;

  /**
   * The bottom menu items for the sidebar.
   */
  SIDEBAR_BOTTOM_MENU = SIDEBAR_BOTTOM_MENU;

  SIDEBAR_MENU_ITEMS = MENU_ITEMS;
  version = environment.VERSION;

  constructor(
    private readonly confirmationDialogService: ConfirmationDialogService,
    private readonly authService: AuthService,
    private readonly toastMessageService: ToastMessageService,
    private readonly router: Router,
    private readonly permissionService: PermissionService,
  ) {}

  isActive(route: string): boolean {
    const currentRoute = this.router.url.split(/[\\/?]/)[1] || '';
    return `/${currentRoute}` === route;
  }

  checkVisibilityByPermission(
    permission: AllGroupPermissions | AllGroupPermissions[] | null,
  ): boolean {
    if (!permission) return true; // if permission not passed return true
    if (isArray(permission)) {
      return permission.some((perm) => this.permissionService.hasPermission(perm));
    }
    return this.permissionService.hasPermission(permission);
  }

  /**
   * Handles the logout functionality.
   * This function opens a confirmation dialog and performs the logout operation.
   */
  handleLogout() {
    this.confirmationDialogService.openDialog({
      title: 'Logout',
      description: 'Are you sure you want to logout?',
      confirmCallback: this.logoutSuccessCallback.bind(this),
    });
  }

  /**
   * Handles the logout success callback.
   * This function is called when the logout operation is successful.
   */
  logoutSuccessCallback() {
    this.authService
      .logout()
      .then(() => {
        this.toastMessageService.showSuccessToast(
          'You have been successfully logged out.',
          'Logged out',
        );
      })
      .catch((httpError: HttpErrorResponse) => {
        this.toastMessageService.showValidationErrors(httpError.error);
      });
  }
}
