export enum NotificationGroup {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  OLDER = 'Older',
}

export enum AppNotificationType {
  CLAIM_CREATED = 'Claim_Created',
  CLAIMED_ASSIGNED = 'Claim_Assigned',
  CLAIM_UNASSIGNED = 'Claim_UnAssigned',
  CLAIM_DUE_REMINDER = 'Claim_Due_Reminder',
  CLAIM_DELAY_REMINDER = 'Claim_Delay_Reminder',
  TASK_CREATED = 'Task_Created',
  TASK_ASSIGNED = 'Task_Assigned',
  TASK_UNASSIGNED = 'Task_UnAssigned',
  TASK_DUE_REMINDER = 'Task_Due_Reminder',
  TASK_DELAYED_REMINDER = 'Task_Delay_Reminder',
}
