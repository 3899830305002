<div class="locationDiv" (click)="openGoogleMaps()" (keyup.enter)="openGoogleMaps()">
  <div class="title-div d-flex">
    <div id="icon-container">
      <img width="24" height="24" src="assets/icons/map-marker.svg" alt="sun" />
    </div>
    <div id="widgetTitle">Location</div>
    <div id="city-name">{{ countryName }}, {{ cityName }}</div>
  </div>
  <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events -->
  <div class="maps-Container">
    <div id="map"></div>
    <div class="overlay-container"></div>
  </div>
</div>
