import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TokenService } from '@Services/token/token.service';

/**
 * The guard for protecting routes that require authentication.
 * @param route The route to be activated.
 * @param state The state of the route.
 * @returns True if the user is authenticated, otherwise false.
 */
export const AuthGuard: CanActivateFn = () => {
  if (
    inject(TokenService).isAuthenticated() ||
    (window.location.search.includes('access_token') &&
      window.location.search.includes('refresh_token'))
  ) {
    return true;
  } else {
    inject(Router).navigate(['/auth/login']);
    return false;
  }
};
