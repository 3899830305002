import { EnvironmentService } from '@Services/environment/environment.service';
import { LoaderService } from '@Services/loader/loader.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ByPassUrls } from '@constants/byPassUrls.const';
import { Observable, finalize } from 'rxjs';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private readonly environmentService: EnvironmentService,
    private loaderService: LoaderService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const hideLoader = request.headers.get('hide-loader');
    if (!hideLoader) {
      this.loaderService.showLoader();
    }
    request = request.clone({
      headers: request.headers.delete('hide-loader'),
    });

    let url = request.url;
    const shouldByPass = ByPassUrls.some((url) => request.url.includes(url));
    if (!shouldByPass) {
      url = `${this.environmentService.getApiURL()}/${request.url}`;
    }
    const modifiedRequest = request.clone({
      url: url,
    });

    return next.handle(modifiedRequest).pipe(
      finalize(() => {
        if (!hideLoader) {
          this.loaderService.hideLoader();
        }
      }),
    );
  }
}
