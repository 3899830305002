<div class="profile-picture-container">
  <div class="full-width d-flex flex-column align-items-center">
    <div class="mt-50">
      <app-avatar
        [name]="fullName"
        [width]="200"
        [height]="200"
        borderColor="#2777BD"
        backgroundColor="#0E346B"
        [image]="user?.profile_picture?.document"
      />
    </div>
    <div class="info">
      <p class="name">{{ fullName }}</p>
      <p class="role">{{ groupName }}</p>
    </div>
    @if (isMyProfile) {
      <p-button
        class="change-profile-pic-button mt-30"
        icon="pi pi-pencil"
        label="Change Picture"
        size="small"
        (click)="openProfilePicture()"
        (keyup.enter)="openProfilePicture()"
        tabindex="0"
      />
    }
  </div>
</div>

<app-upload-profile-picture-dialog
  [profilePictureDialogVisible]="profilePictureDialogVisible"
  [user]="user"
  (toggleDialogHandler)="toggleProfilePictureDialog()"
  (uploadProfilePictureHandler)="uploadProfilePicture($event)"
/>
