@if (user) {
  <div class="sidebar" (mouseenter)="onSidebarHover(true)" (mouseleave)="onSidebarHover(false)">
    <app-side-bar [sidebarExpand]="sidebarExpand"></app-side-bar>
  </div>
}

<div class="content" [ngClass]="{ 'open': sidebarExpand }">
  @if (user) {
    <app-header></app-header>
  }
  @if (latestProfileDataFetched) {
    <div class="route-content">
      <router-outlet></router-outlet>
    </div>
  }
</div>
