import { AllGroupPermissions } from '@shared/enums/all-group-permissions/permissions';
import { PrimeIcons } from 'primeng/api';

export enum ProjectTabKeys {
  GENERAL = 'general',
  TASKS = 'tasks',
  CLAIMS = 'claims',
  INVOICES = 'invoices',
  PERFORMANCE = 'performance',
  EQUIPMENT = 'equipment',
}

export const NAVIGATION_TABS = [
  {
    title: 'General',
    icon: PrimeIcons.CALENDAR,
    routerLink: './',
    key: ProjectTabKeys.GENERAL,
    permission: AllGroupPermissions.VIEW_PROJECT,
  },
  {
    title: 'Tasks',
    icon: PrimeIcons.LIST,
    routerLink: './task',
    key: ProjectTabKeys.TASKS,
    permission: AllGroupPermissions.VIEW_TASK,
  },
  {
    title: 'Claims',
    icon: PrimeIcons.BOOK,
    routerLink: './claim',
    key: ProjectTabKeys.CLAIMS,
    permission: AllGroupPermissions.VIEW_CLAIM,
  },
  {
    title: 'Invoices',
    icon: PrimeIcons.MONEY_BILL,
    routerLink: './invoice',
    permission: AllGroupPermissions.VIEW_INVOICE,
    key: ProjectTabKeys.INVOICES,
  },
  {
    title: 'Performance',
    icon: PrimeIcons.CHART_LINE,
    routerLink: './performance',
    key: ProjectTabKeys.PERFORMANCE,
    permission: AllGroupPermissions.VIEW_PROJECT_PERFORMANCE,
  },
  {
    title: 'Asset Tree',
    icon: PrimeIcons.WRENCH,
    routerLink: './asset-tree',
    key: ProjectTabKeys.EQUIPMENT,
    permission: AllGroupPermissions.VIEW_EQUIPMENT,
  },
];
