<!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events -->
<div class="weatherDiv" (click)="openWeatherDetails()" (keyup.enter)="openWeatherDetails()">
  <div class="weatherContainer">
    <div class="column1">
      <div id="icon-container">
        <img width="24" height="24" src="assets/icons/sun.svg" alt="sun" />
      </div>
    </div>
    <div class="column2">
      <span id="weatherTitle">Weather</span>
      <div *ngIf="weatherData">
        <img [src]="weatherIcon" alt="" srcset="" />
      </div>
    </div>
    <div *ngIf="weatherData" class="column3">
      <div id="day">{{ today | date: 'EEEE' }}</div>
      <div id="temp-degree">{{ weatherData.main.temp | number: '1.0-0' }}&deg;</div>
      <div id="temp-fahren">
        {{ (celsiusToFahrenheit(weatherData.main.temp) | number: '1.2-2') + ' F' }}
      </div>
    </div>
  </div>
  <div class="overlay"></div>
</div>
