import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PageTitleComponent } from '@shared/components/page-title/page-title.component';
import { ConfirmationDialogData } from '@shared/interfaces/confirmation-dialog.interface';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [ButtonModule, PageTitleComponent, CommonModule, SkeletonModule, DialogModule],
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<ConfirmationDialogData>,
  ) {}

  get title(): string {
    return this.config?.data?.title ?? '';
  }

  get description(): string {
    return this.config?.data?.description ?? '';
  }

  get subDescription(): string {
    return this.config?.data?.subDescription ?? '';
  }

  closeDialog(confirmation: boolean): void {
    this.ref?.close(confirmation);
  }
}
